import React from 'react';
import { Link } from 'gatsby';

import SEO from 'sections/Layout/SEO';

import {
  galaxus,
  inner,
  emailWrap,
  links,
  link,
} from 'styles/pages/galaxus/index.module.scss';

const seo = {
  title: 'Galaxus - Email Survey | Survey Promo | Screver',
  metaDesc:
    'Look at the Galaxus email survey with Screver Feedback Management Solution. It allows you to attract, analyze in real-time, and implement data-driven actions.',
  opengraphUrl: 'https://screver.com/cases/cx/galaxus/',
};

const Galaxus = () => {
  return (
    <>
      <SEO seo={seo} themeColor="#ffffff" />

      <div className={galaxus}>
        <div className={inner}>
          <div className={emailWrap}>
            <img
              src="https://wp.screver.com/wp-content/uploads/2023/08/Frame-5158.png"
              alt=""
            />

            <div className={links}>
              <Link to="/cases/cx/galaxus/survey-1" className={link}>
                <img
                  src="https://wp.screver.com/wp-content/uploads/2023/08/Icons-combination.svg"
                  alt=""
                />
              </Link>
              <Link to="/cases/cx/galaxus/survey-2" className={link}>
                <img
                  src="https://wp.screver.com/wp-content/uploads/2023/08/Icons-combination-1.svg"
                  alt=""
                />
              </Link>
              <Link to="/cases/cx/galaxus/survey-3" className={link}>
                <img
                  src="https://wp.screver.com/wp-content/uploads/2023/08/Icons-combination-2.svg"
                  alt=""
                />
              </Link>
              <Link to="/cases/cx/galaxus/survey-4" className={link}>
                <img
                  src="https://wp.screver.com/wp-content/uploads/2023/08/Icons-combination-3.svg"
                  alt=""
                />
              </Link>
              <Link to="/cases/cx/galaxus/survey-5" className={link}>
                <img
                  src="https://wp.screver.com/wp-content/uploads/2023/08/Icons-combination-4.svg"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Galaxus;
